<template>
  <div class="bok_con">
    <back />
    <!-- <div class="bok_hea"></div> -->
    <!-- <div> -->
      <img class="bok_hea" src="../../assets/user/zshead.png" alt="" />
    <!-- </div> -->
    <van-pull-refresh
      v-model="isLoading"
      style="height: calc(100% - 65vw); overflow-y: auto"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <div v-if="bookList && bookList.length" class="itm_wra">
        <div v-for="(item, index) in bookList" :key="index" @click="enterDetails(item)">
          <div class="itm_lst">
            <div class="itm_img">
              <img :src="item.certificateCount" alt="" />
              <img
                class="abs_img"
                v-show="item.certificateStatus === 1"
                src="../../assets/user/shz.png"
                alt=""
              />
              <img
                class="abs_img"
                v-show="item.certificateStatus === 2"
                src="../../assets/user/ytg.png"
                alt=""
              />
              <img
                class="abs_img"
                v-show="item.certificateStatus === 3"
                src="../../assets/user/ybh.png"
                alt=""
              />
            </div>
            <span class="itm_spn">{{ item.certificateTypeName }}</span>
          </div>
        </div>
      </div>
    </van-pull-refresh>

    <div class="bok_btn" @click="$router.push('/addbook')">添加我的证书</div>
  </div>
</template>

<script>
import PersonApi from "@/api/person";

export default {
  data() {
    return {
      bookList: [],
      isLoading: false,
    };
  },
  mounted() {
    this.queryBookList();
  },
  methods: {
    // 查询证书列表
    queryBookList() {
      PersonApi.getBookList({ appName: this.appName })
        .then((res) => {
          this.isLoading = false;
          if (res.success) {
            this.bookList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 进入详情页
    enterDetails(val) {
      console.log(val);
      this.$router.push({
        path: "/bookDetail",
        query: {
          id: val.id,
          certificatePath: val.certificateCount,
          certificateTypeId: val.certificateTypeId,
          certificateTypeName: val.certificateTypeName,
          aduitText: val.aduitText,
          status: val.certificateStatus,
        },
      });
    },

    // 下拉刷新
    onRefresh() {
      this.queryBookList();
    },
  },
};
</script>

<style lang="less" scoped>
.bok_con {
  position: relative;
  padding-bottom: 50px;
  .bok_hea {
    height: 63vw;
    width: 100%;
    object-fit: cover;
    // background: url("../../assets/user/zshead.png");
    // background-size: cover;
    // background-repeat: no-repeat;
    // position: relative;
  }
  .itm_wra {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .itm_lst {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px 10px;
      .itm_img {
        width: 40vw;
        height: 40vw;
        border: 1px solid #bebdbd;
        padding: 5px;
        position: relative;
        border-radius: 4px;
        & :nth-child(1) {
          width: 100%;
          height: 100%;
        }
        .abs_img {
          width: 65px;
          height: auto;
          position: absolute;
          top: 5px;
          right: -10px;
        }
      }
      .itm_spn {
        display: inline-block;
        width: 120px;
        text-align: center;
      }
    }
  }
  .bok_btn {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);
    width: 60vw;
    height: 40px;
    line-height: 40px;
    color: #fff;
    background: #1377e2;
    text-align: center;
    border-radius: 5px;
    font-size: 18px;
  }
}
</style>
